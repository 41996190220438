import React from 'react'
import { createRoot } from 'react-dom/client'

import Favourites from './Favourites/Favourites'
import { LightSearch } from './Search/LightSearch'
import { Search } from './Search/Search'
import { ListingCategory, Request, SearchData, OptionType } from './Search/Search.types'
import Sort from './Sort/Sort'


declare global {
  interface Window {
    CURRENCY: string
    SEARCH_PARAMS: unknown
    G_MAP_API_KEY: string
    DOWNLOAD_TITLE: string
    DOWNLOAD_LINK: string
    DOWNLOAD_SIZE: string
    DETAIL_PAGE_ARTICLE_PUBLISH_DATE: string
    DETAIL_PAGE_ARTICLE_DESCRIPTION: string
    DETAIL_PAGE_ARTICLE_LINK: string
    DETAIL_PAGE_ARTICLE_TITLE: string
    AGENT_LISTINGS: string
    AGENT_BRANCH: string
    AGENT_PHONE: string
    AGENT_NAME: string
    FORMAT_CURRENCY: string
    PROPERTY_PRICE: string
    DETAIL_PAGE_CONFIG: unknown
    // eslint-disable-next-line @typescript-eslint/ban-types
    SORT_CHANGE: Function
    SORT_SEARCHABLE: boolean
    SORT_PLACEHOLDER: string
    PAGE_PATH: string
    setSortValue: unknown
    request: Request
    searchData: SearchData
    categoriesForSale: ListingCategory[]
    categoriesToLet: ListingCategory[]
    SORT_OPTIONS: OptionType<string>[]
    SORT_SHOW_VALUE: boolean
    REACT_SEARCH_BOX_THEME?: 'DARK' | 'LIGHT'
    STICKY_NAVBAR_TYPE?: 'DEFAULT' | 'COMMERCIAL' | 'NEW_DEVELOPMENT' | 'ON_SHOW' | 'DETAILS' | 'TENDER'
  }
}

let search_root
let results_sort
let results_sort_2
let light_search_root
let favourites_handler
if (document.getElementById('light-search-root')) {
  light_search_root = createRoot(document.getElementById('light-search-root'))
}
if (document.getElementById('search-root')) {
  search_root = createRoot(document.getElementById('search-root'))
}
if (document.getElementById('results-sort')) {
  results_sort = createRoot(document.getElementById('results-sort'))
}
if (document.getElementById('results-sort-2')) {
  results_sort_2 = createRoot(document.getElementById('results-sort-2'))
}
if (document.querySelector('.favourites-handler')) {
  favourites_handler = createRoot(document.querySelector('.favourites-handler'))
}

if (light_search_root) {
  light_search_root.render(
    <div>
      <LightSearch />
    </div>
  )
}


if (search_root) {
  search_root.render(
    <div>
      <Search />
    </div>
  )
}

if (results_sort) {
  results_sort.render(<Sort />)
}

if (results_sort_2) {
  results_sort_2.render(<Sort />)
}

if (favourites_handler) {
  favourites_handler.render(<Favourites />)
}
