import merge from 'deepmerge'
import { getIn } from 'formik'
import React, { ReactNode, useEffect, useState, Children, isValidElement, cloneElement, useRef, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import Select, { MultiValueRemoveProps, ClearIndicatorProps, ContainerProps, components, ControlProps, ValueContainerProps, OptionProps, GroupBase, MultiValueProps } from 'react-select'

import { FormSelectProps } from './FormSelect.types'
import { MoreSearchOptionsIcon, CrossIcon } from '../../icons'
import { OptionType, Suburb } from '../../Search/Search.types'


declare module 'react-select/base' {
  export interface Props<
    Option,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    truncate?: number
    isChecks?: boolean
    icon?: ReactNode;
  }
}

const styleProxy = new Proxy({}, {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get: () => () => {}
})

const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <CrossIcon />
  </components.MultiValueRemove>
)

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <CrossIcon />
  </components.ClearIndicator>
)

const CustomOption = (props: OptionProps) => {
  const selectOption = getIn(props, 'selectOption')
  if (!props.selectProps.isChecks) {
    return <components.Option {...props} />
  }
  // @ts-ignore
  return <components.Option
    {...props}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    selectOption={() => {}}
    isFocused={false}
  >
    <div className="customopt">
      <label className="checkcontainer">
        <input
          type="checkbox"
          checked={getIn(props, 'isSelected')}
          onChange={() => selectOption(getIn(props, 'value'))}
        />
        <span className={'checkmark'}>
          {getIn(props, 'isSelected') && getIn(props, 'isSelected') !== 'false' ? <svg viewBox="0 0 17 13"><use href="#icon16-Check-Small" /></svg> : null}
        </span>
      </label>
      {getIn(props, 'label')}
    </div>
  </components.Option>
}

interface NestedGroupProps extends GroupBase<OptionType<string>>, OptionProps {
  headingProps: unknown
  id: unknown,
  label: string,
  options: OptionType<string>[]
  data: OptionType<string>
}

const renderNestedOption = (props: NestedGroupProps) => {
  const {
    headingProps,
    innerProps,
    data,
    selectOption
  } = props
  const nestedOptions = data.options || []
  const children = nestedOptions.map((nestedOption: OptionType<string>) => {
    if (nestedOption.options) {
      return renderNestedOption({
        ...props,
        data: nestedOption,
        innerProps: { ...innerProps, onClick: null }
      })
    }

    const nestedInnerProps = {
      ...innerProps,
      onClick: () => {
        selectOption(nestedOption)
      }
    }
    let isSelected = false
    const value = props.selectProps.value
    if (props.isMulti && Array.isArray(value)) {
      if (nestedOption.area && value
        .map(x => x.value).includes(nestedOption.area)) {
        isSelected = true
      } else if (
        nestedOption.province
        && value.map(x => x.province).length
        && !value
          .map(x => x.province).includes(nestedOption.province)) {
        isSelected = true
      } else {
        isSelected = value.map(x => x.value).includes(nestedOption.value)
      }
    } else {
      isSelected = props.selectProps.value === nestedOption.value
    }
    if (isSelected) { return null }
    return (
      <div className="nested-optgroup-option" key={nestedOption.value as string}>
        {
          (
            <components.Option
              {...{
                ...props,
                label: nestedOption.label,
                value: nestedOption.value,
                isSelected,
                data: nestedOption
              }}
              // @ts-ignore
              innerProps={nestedInnerProps}>
              {nestedOption.label}
            </components.Option>
          ) as ReactNode
        }
      </div>
    )
  }).filter(child => child)
  // Will be applied to nested optgroup headers
  const groupProps = {
    ...props,
    label: data.label,
    options: nestedOptions,
    headingProps: headingProps ? headingProps : { id: innerProps.id, data }
  }

  if (!children.length) {
    // @ts-ignore
    return null
  }
  // @ts-ignore
  return <components.Group key={data.label} {...groupProps}>{children}</components.Group>
}

const MoreSelectedBadge = ({ items, setMoreElement }) => {
  const title = items.join(', ')
  const length = items.length
  const label = `+${length}`

  return (
    <div className="react-select__multi-value-holder" title={title} ref={el => {
      setMoreElement(el)
    }}>
      {label}
    </div>
  )
}

interface CustomMultiValueProps extends MultiValueProps {
  getValue: () => OptionType<string>[]
  truncate: number
  setMoreElement: () => HTMLDivElement
}

const MultiValue = ({ index, getValue, isFocused, truncate, setMoreElement, ...props }: CustomMultiValueProps) => {
  const customClassNames = useCallback(() => {
    if (index >= truncate && !isFocused) {
      return 'react-select__multi-value--is-hidden'
    }
    return ''
  }, [ index, truncate, isFocused ])
  const overflow = getValue()
    .slice(truncate)
    .map(x => x.label)
  if ((isFinite(truncate) && index < truncate) || isFocused) {
    // @ts-ignore
    return <components.MultiValue
      {...props}
      index={index}
      getValue={getValue}
      isFocused={isFocused}
      getClassNames={customClassNames}
    />
  }

  if (index === truncate) {
    return <>
      <MoreSelectedBadge items={overflow} setMoreElement={setMoreElement} />
      <components.MultiValue
        {...props}
        index={index}
        getValue={getValue}
        isFocused={isFocused}
        getClassNames={customClassNames}
      />
    </>
  }

  // @ts-ignore
  return <components.MultiValue
    {...props}
    index={index}
    getValue={getValue}
    isFocused={isFocused}
    getClassNames={customClassNames}
  />
}

interface CustomValueContainerProps {
  innerProps: boolean
  isFocused: boolean
}

const Control = ({
  children,
  isFocused,
  ...props
}: ControlProps) => <components.Control {...props} isFocused={isFocused}>
  {Children.map(children, child => {
    if (isValidElement(child)) {
      // @ts-ignore
      return cloneElement(child, { isFocused })
    }
    return child
  })}
</components.Control>

const SelectedValuesContainer = ({
  isDisabled,
  isFocused,
  getValue,
  children,
  ...props
}: ValueContainerProps & CustomValueContainerProps) => {
  // @ts-ignore
  const [ valueChildren, inputChild ] = children
  const [ element, setElement ] = useState<HTMLDivElement>()
  const [ moreElement, setMoreElement ] = useState<HTMLDivElement>()
  const [ moreWidth, setMoreWidth ] = useState<number>(0)
  const [ truncate, setTruncate ] = useState<number>(0)
  const [ hidden, setHidden ] = useState<number>(0)
  const observer = useRef<IntersectionObserver>()

  const checkWidth = useCallback((entries: IntersectionObserverEntry[]) => {
    let count = 0
    let width = moreWidth
    for (const entry of entries) {
      width += entry.intersectionRect.width + 4
      if (width < entry.rootBounds.width && entry.target.classList.contains('react-select__multi-value')) {
        count++
      }
    }
    setHidden(entries.length - count)
    if (count === 0) {
      count = 1
    }
    setTruncate(count)
  }, [ moreWidth ])

  useEffect(() => {
    observer.current = new IntersectionObserver(checkWidth, {
      root: element,
      rootMargin: `0px 0px ${moreWidth || 0}px 0px`,
      threshold: 1.0
    })
    if (moreElement) {
      setMoreWidth(moreElement.offsetWidth)
      observer.current.observe(moreElement)
    }
    if (element) {
      const values = element.querySelectorAll<HTMLElement>('.react-select__multi-value')
      values.forEach(el => {
        observer.current.observe(el)
      })
    }
    return () => {
      observer.current?.disconnect()
    }
  }, [ element, valueChildren?.length, truncate, moreWidth, hidden, moreElement ])
  const container = <components.ValueContainer {...props} isDisabled={isDisabled} getValue={getValue}>
    <div className='react-select__expanding-container' ref={el => setElement(el)}>
      <components.Placeholder {...props} isDisabled={isDisabled} getValue={getValue} isFocused={isFocused}>
        {!isFocused && !props.hasValue ? props.selectProps.placeholder : null}
        {inputChild}
      </components.Placeholder>
      {Children.map(valueChildren, child => {
        if (child.key === 'placeholder') {
          return null
        }
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { isFocused, truncate, setMoreElement })
        }
        return child
      })}
    </div>
  </components.ValueContainer>
  return (
    <>
      {props.selectProps.icon}
      {container}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Group = (props: any) => renderNestedOption({
  ...props
})

const SelectContainer = ({
  children,
  className,
  innerProps,
  isFocused,
  ...commonProps
}: ContainerProps) => {
  const selectContainerProps = {
    ...commonProps
  }

  return (
    <components.SelectContainer
      className={`${className}${isFocused ? ' react-select__is-focused' : ''}`}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      {Children.map(children, child => {
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { isFocused })
        }
        return child
      })}
    </components.SelectContainer>
  )
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MoreSearchOptionsIcon />
  </components.DropdownIndicator>
)


const filterOptions = (options: OptionType<string>[], inputValue: string) : OptionType<string>[] | null => {
  if (inputValue) {
    const newOptions = options.map(o => {
      if (o.options) {
        return {
          ...o,
          options: filterOptions(o.options, inputValue)
        }
      }
      if (o.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        return merge({}, o)
      }
      return null
    }).filter(o => o)
    // @ts-ignore
    return newOptions
  }
  return options
}

export const parseOptions = (
  options: (OptionType<string> | Suburb)[] | [],
  list: (OptionType<string> | Suburb)[] = []
) => {
  for (const option of options) {
    if (option.options) {
      parseOptions(option.options, list)
    } else {
      list.push(option)
    }
  }
  return list
}

export function FormSelect<Form>({
  field,
  form,
  options,
  placeholder,
  components: comps,
  isMulti,
  isChecks,
  defaultValue,
  icon,
  truncate,
  ...props
}: FormSelectProps<Form>): ReactNode {
  const [ flatOptions, setFlatOptions ] = useState<OptionType<string>[]>([])
  const [ filteredOptions, setFilteredOptions ] = useState(options)
  const [ inputValue, setInputValue ] = useState('')
  // @ts-ignore
  useEffect(() => setFlatOptions(parseOptions(options)), [ options ])

  useEffect(() => {
    const newOptions = filterOptions(options, inputValue)
    // @ts-ignore
    setFilteredOptions(newOptions)
  }, [ inputValue, options ])

  const values = flatOptions.filter(x => {
    if (isMulti && Array.isArray(getIn(form.values, field.name))) {
      return getIn(form.values, field.name).includes(x.value)
    }
    return isEqual(
      x.value,
      getIn(form.values, field.name)
    )
  })

  function handleInputChange(val: string, e: { action: string }) {
    if (e.action === 'input-change') {
      setInputValue(val)
    }
  }

  useEffect(() => {
    if ([ null, undefined ].includes(field.value) && ![ null, undefined ].includes(defaultValue)) {
      form.setFieldValue(field.name, defaultValue)
    }
  }, [ defaultValue ])

  const hideSelectedOptions = ![
    null,
    undefined
  ].includes(props.hideSelectedOptions) ? props.hideSelectedOptions : !isChecks

  const value = isMulti ? values : values[0]
  let placeholderText = placeholder || ''
  if (isChecks && isMulti) {
    placeholderText = `${values.length} Selected`
  }
  return <div className={`input-field select-field field-${field.name}`}>
    <Select
      placeholder={placeholderText}
      defaultValue={value}
      value={value}
      className={'react-select'}
      classNamePrefix="react-select"
      onChange={v => {
        if (isMulti) {
          form.setFieldValue(
            field.name,
            // @ts-ignore
            v && Array.isArray(v) ? v.map((x: { value: string }) => x.value) : undefined
          )
          form.setFieldTouched(field.name)
        } else {
        // @ts-ignore
          form.setFieldValue(field.name, v ? v.value : undefined)
          form.setFieldTouched(field.name)
        }
      }}
      icon={icon}
      truncate={truncate}
      filterOption={() => true}
      onInputChange={handleInputChange}
      onMenuClose={() => setInputValue('')}
      closeMenuOnSelect={!isChecks}
      hideSelectedOptions={hideSelectedOptions}
      controlShouldRenderValue={!isChecks}
      isChecks={isChecks}
      inputValue={inputValue}
      options={filteredOptions}
      isMulti={isMulti}
      isSearchable={isMulti ? true : false}
      styles={styleProxy}
      components={{
        Group,
        ValueContainer: SelectedValuesContainer,
        Control,
        MultiValue,
        DropdownIndicator,
        Option: CustomOption,
        SelectContainer: SelectContainer,
        ClearIndicator,
        MultiValueRemove,
        ...comps
      }}
    />
  </div>
}
