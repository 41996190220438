/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Buffer } from 'buffer'


const Favourites = () => {
  const [ favourites, setFavourites ] = React.useState(document.querySelectorAll('.results-favourites-button'))
  const [ ids, setIds ] = React.useState(false)
  const el = React.useRef(null)
  const getFavoriteProperties = () => {
    const data = localStorage.getItem('favoriteProperties')
    return data ? data.split(',') : []
  }

  const handleClick = e => {
    e.preventDefault()
    setIds(getFavoriteProperties())
  }
  useEffect(() => {
    favourites.forEach(btn => {
      btn.addEventListener('click', handleClick)
    })
    return () => {
      favourites.forEach(btn => {
        btn.removeEventListener('click', handleClick)
      })
    }
  }, [ favourites ])
  useEffect(() => {
    if (el && el.current) {
      el.current.submit()
    }
  }, [ el.current, ids ])
  return Array.isArray(ids) && ids.length ? (
    <form ref={el} method="GET" action="/results/my-favourites/">
      <input type="hidden" name="ids" value={Buffer.from(JSON.stringify(ids)).toString('base64')} />
    </form>
  ) : null
}

export default Favourites
