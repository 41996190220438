import merge from 'deepmerge'


export const uniqueArray = (array, prop) => {
  const result = []
  let map = new Map()
  for (const item of array) {
    if (typeof item === 'object' && prop && !map.has(item[prop])) {
      map = map.set(item[prop], true) // set any value to Map
      result.push(merge({}, item)) // return a copy of the object
    } else if (typeof item !== 'object' && !map.has(item)) {
      map = map.set(item, true) // set any value to Map
      result.push(item)
    }
  }
  return result
}
