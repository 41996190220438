import React from 'react'
import { ButtonProps } from './Button.types'


const Button = (props: ButtonProps) : JSX.Element => {
  const { children, ...otherProps } = props
  return <button type='button' className={`search-button button ${props.variant ?? 'transparent'}`} {...otherProps}>
    {children}
  </button>
}


export default Button
