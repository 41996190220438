import React from 'react'

import { FormSelect } from './FormSelect/FormSelect'
import { FormSelectProps } from './FormSelect/FormSelect.types'
import { SearchForm } from '../Search/Search.types'


const SearchFormSelect = (props: FormSelectProps<SearchForm>) => <FormSelect {...props} />


export default SearchFormSelect
