import { useRef } from 'react'
import isEqual from 'react-fast-compare'


export default function useCustomCompareMemo(value) {
  const ref = useRef(value)

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
