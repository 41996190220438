import { Buffer } from 'buffer'

import axios from 'axios'
import { Field, Form, Formik, getIn } from 'formik'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Options } from 'react-select'

import { SearchForm, SearchFormData, OptionType } from './Search.types'
import Button from '../Button/Button'
import { SearchIcon, PinIcon } from '../icons'
import { loadOptions } from '../selects/FormSelect/FormSelect.types'
import { LocationSelect } from '../selects/LocationSelect'
import SearchFormSelect from '../selects/SearchFormSelect'
import { uniqueArray } from '../utils/uniqueArray'
import UrlParser from '../utils/UrlParser'
import '../styles/search-light.scss'


const api = (url: string) => `/v2/ajax${url}`

type Categories = {
  'For Sale': Map<string, number>;
  'To Let': Map<string, number>;
};

const categories: Categories = {
  'For Sale': new Map<string, number>(),
  'To Let': new Map<string, number>()
}

const CATEGORIES = [
  'Residential',
  'Residential Development',
  'Residential Estate',
  'Residential Vacant Land',
  'Commercial',
  'Commercial and Industrial Development',
  'Commercial Estate',
  'Commercial & Industrial Vacant Land',
  'Industrial',
  'Mixed Use',
  'Retail',
  'Agricultural',
  'Farms & Small Holdings',
  'Tenders',
  'Auction',
  'Bank Repossessed',
  'Bank Assisted',
  'Auctions',
  'Holiday Accommodation',
  'Student Accommodation'
]

let buySelectOptions: OptionType<number>[] = []

let countTimer: ReturnType<typeof setTimeout>

const csrftoken = Cookies.get('csrftoken')

const initialSearch: SearchForm = {}
if (window.request.search_defaults.branch__in) {
  initialSearch.branch__in = window.request.search_defaults.branch__in
}

const initializeSearch = () => axios.post(api('/setup-search/'), initialSearch, { headers: { 'X-CSRFToken': csrftoken } }).then(resp => {
  window.searchData = resp.data
  Object.keys(window.searchData).forEach(key => {
    let c_key: string
    if ([
      'For Sale'
    ].includes(key)) {
      c_key = 'For Sale'
      buySelectOptions.push({ value: c_key, label: c_key })
    }
    if ([
      'To Let'
    ].includes(key)) {
      c_key = 'To Let'
      buySelectOptions.push({ value: c_key, label: c_key })
    }
    buySelectOptions = uniqueArray(buySelectOptions, 'value')
    buySelectOptions.sort((a, b) => [ 'For Sale', 'To Let' ].indexOf(a.value as string) - [ 'For Sale', 'To Let' ].indexOf(b.value as string))
    getIn(window.searchData, key).forEach(listing_type => {
      categories[c_key as keyof Categories].set(listing_type, 1)
    })
  })
  return window.searchData
}).catch(e => console.error(e))

const buyRentMap = {
  Buy: 'For Sale',
  Rent: 'To Let'
}

let defaultCategory = 'Residential'
if (window.request.search_defaults?.category) {
  defaultCategory = window.request.search_defaults?.category
}
if (window.request.params?.sub) {
  const { sub, area, suburb, property_type, listing_type } = window.request.params
  switch (sub) {
    case 'residential':
      defaultCategory = 'Residential'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        defaultCategory = 'Residential Vacant Land'
      }
      break
    case 'commercial':
      defaultCategory = 'Commercial'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        defaultCategory = 'Commercial & Industrial Vacant Land'
      }
      break
    case 'industrial':
      defaultCategory = 'Industrial'
      break
    case 'retail':
      defaultCategory = 'Retail'
      break
    case 'agricultural':
      defaultCategory = 'Agricultural'
      break
    case 'mixed-use':
      defaultCategory = 'Mixed Use'
      break
    case 'farms-and-small-holdings':
      defaultCategory = 'Farms & Small Holdings'
      break
    case 'new-development':
      if (listing_type === 'residential') {
        defaultCategory = 'Residential Development'
      } else {
        defaultCategory = 'Commercial & Industrial New Development'
      }
      break
    case 'estate':
      if (listing_type === 'residential') {
        defaultCategory = 'Residential Estate'
      } else {
        defaultCategory = 'Commercial & Industrial Estate'
      }
      break
    case 'holiday':
      defaultCategory = 'Holiday Accommodation'
      break
    case 'residential-vacant-land':
      defaultCategory = 'Residential Vacant Land'
      break
    case 'commercial-vacant-land':
      defaultCategory = 'Commercial & Industrial Vacant Land'
      break
    default:
      defaultCategory = window.request.search_defaults ? window.request.search_defaults.category : 'Residential'
  }
}

let initialForm: SearchForm = {
  buy_rent: getIn(buyRentMap, window.request?.search_defaults?.buy_rent, 'For Sale'),
  category: defaultCategory,
  locations: [],
  property_type__in: [],
  min_beds: undefined,
  min_baths: undefined,
  min_price: undefined,
  max_price: undefined,
  size_from: undefined,
  size_to: undefined,
  on_show: undefined,
  has_flat_or_accomm: undefined,
  pets_allowed: undefined,
  furnished: undefined,
  parking_type: undefined,
  min_parking: undefined
}

if (window.request.previous_search) {
  initialForm = {
    ...window.request.previous_search,
    min_beds: undefined,
    min_baths: undefined,
    min_price: undefined,
    max_price: undefined,
    size_from: undefined,
    size_to: undefined,
    on_show: undefined,
    has_flat_or_accomm: undefined,
    pets_allowed: undefined,
    furnished: undefined,
    parking_type: undefined,
    min_parking: undefined
  }
}
const local_search = window.localStorage.getItem('pd_search')
if (initialForm.flatlet !== undefined) {
  delete initialForm.flatlet
  window.localStorage.setItem('pd_search', JSON.stringify(initialForm))
}

if (window.request.search_defaults.branch__in) {
  initialForm.branch__in = window.request.search_defaults.branch__in
} else if (local_search) {
  initialForm = {
    ...JSON.parse(local_search),
    min_beds: undefined,
    min_baths: undefined,
    min_price: undefined,
    max_price: undefined,
    size_from: undefined,
    size_to: undefined,
    on_show: undefined,
    has_flat_or_accomm: undefined,
    pets_allowed: undefined,
    furnished: undefined,
    parking_type: undefined,
    min_parking: undefined
  }
}

if (initialForm.category?.includes('&amp;')) {
  initialForm.category = initialForm.category.replace('&amp;', '&')
}

const urlParser = new UrlParser(`${window.location.pathname}${window.location.search}`)

const prefillInitialForm = async (form: SearchForm) => {
  let prefilledForm = { ...form }
  if (getIn(window, 'request.search_params')) {
    const decodedRequestBodyString = Buffer.from(window.request.search_params, 'base64')
    const requestBodyObject = JSON.parse(decodedRequestBodyString.toString())
    prefilledForm = requestBodyObject
  }
  const { sub, listing_type, area, suburb, property_type } = urlParser?.parsedValues?.groups || {
    sub: null,
    listing_type: null,
    area: null,
    suburb: null,
    property_type: null
  }
  if (listing_type === 'for-sale') {
    prefilledForm.buy_rent = 'For Sale'
  }

  if (listing_type === 'to-let') {
    prefilledForm.buy_rent = 'To Let'
  }

  if (prefilledForm.category?.includes('&amp;')) {
    prefilledForm.category = prefilledForm.category.replace('&amp;', '&')
  }

  let parsedCategoryId
  let parsedCategory = ''
  switch (sub) {
    case 'residential':
      parsedCategory = 'Residential'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Residential Vacant Land'
      }
      break
    case 'commercial':
      parsedCategory = 'Commercial'
      if (property_type === 'vacant-land' && area === 'all' && suburb === 'all') {
        parsedCategory = 'Commercial & Industrial Vacant Land'
      }
      break
    case 'industrial':
      parsedCategory = 'Industrial'
      break
    case 'retail':
      parsedCategory = 'Retail'
      break
    case 'agricultural':
      parsedCategory = 'Agricultural'
      break
    case 'mixed-use':
      parsedCategory = 'Mixed Use'
      break
    case 'farms-and-small-holdings':
      parsedCategory = 'Farms & Small Holdings'
      break
    case 'new-development':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Development'
      } else {
        parsedCategory = 'Commercial & Industrial New Development'
      }
      break
    case 'estate':
      if (listing_type === 'residential') {
        parsedCategory = 'Residential Estate'
      } else {
        parsedCategory = 'Commercial & Industrial Estate'
      }
      break
    case 'holiday':
      prefilledForm.buy_rent = 'Rent'
      parsedCategory = 'Holiday Accommodation'
      break
    case 'residential-vacant-land':
      parsedCategory = 'Residential Vacant Land'
      break
    case 'commercial-vacant-land':
      parsedCategory = 'Commercial & Industrial Vacant Land'
      break
    default:
      parsedCategory = window.request.search_defaults ? window.request.search_defaults.category : 'Residential'
  }

  if (categories[prefilledForm.buy_rent as keyof Categories].has(parsedCategory)) {
    parsedCategoryId = parsedCategory
  } else {
    parsedCategoryId = categories[prefilledForm.buy_rent as keyof Categories][0]
  }
  const selectedAreas = getIn(window.request.params, 'area__in__or', []) || []
  const selectedSuburbs = getIn(window.request.params, 'location__in__or', []) || []
  if (selectedAreas.length) {
    prefilledForm.locations = prefilledForm.locations ? uniqueArray([
      ...prefilledForm.locations,
      ...selectedAreas
    ]) : uniqueArray([ ...selectedAreas ])
  }
  if (selectedSuburbs.length) {
    prefilledForm.locations = prefilledForm.locations ? uniqueArray([
      ...prefilledForm.locations,
      ...selectedSuburbs
    ]) : uniqueArray([ ...selectedSuburbs ])
  }

  if (!prefilledForm.category || !categories[prefilledForm.buy_rent as keyof Categories].has(prefilledForm.category)) {
    prefilledForm.category = parsedCategoryId
  }

  return prefilledForm
}

const getPostData = (form: SearchForm, {
  includeAdvanced = true
}): SearchFormData => {
  const { buy_rent, category, property_type__in, ...other } = form
  const data: SearchFormData = {
    model__in: [],
    listing_type: '',
    loc: [],
    property_type__in,
    zoning: ''
  }
  delete other.flatlet
  switch (category) {
    case 'Residential':
      data.listing_type = buy_rent
      data.model__in = [ 'residential' ]
      break
    case 'Residential Vacant Land':
      data.listing_type = buy_rent
      data.model__in = [ 'residential' ]
      data.property_type__in = [ 'Vacant Land' ]
      break
    case 'Commercial':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Commercial'
      break
    case 'Commercial & Industrial Vacant Land':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.property_type__in = [ 'Vacant Land' ]
      break
    case 'Retail':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Retail'
      break
    case 'Industrial':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Industrial'
      break
    case 'Mixed Use':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Mixed Use'
      break
    case 'Agricultural':
      data.listing_type = buy_rent
      data.model__in = [ 'commercial' ]
      data.zoning = 'Agricultural'
      break
    case 'Farms & Small Holdings':
      data.model__in = [ 'residential', 'commercial' ]
      data.listing_type = buy_rent
      data.property_type__in = [ 'Farm', 'Small Holding' ]
      break
    case 'Commercial & Industrial Development':
      data.model__in = [ 'project' ]
      data.listing_type = 'Commercial Development'
      break
    case 'Commercial Estate':
      data.model__in = [ 'project' ]
      data.listing_type = 'Commercial Estate'
      break
    case 'Residential Development':
      data.model__in = [ 'project' ]
      data.listing_type = 'Residential Development'
      break
    case 'Residential Estate':
      data.model__in = [ 'project' ]
      data.listing_type = 'Residential Estate'
      break
    case 'Holiday Accommodation':
      data.model__in = [ 'holiday' ]
      break
    case 'Action':
      data.listing_type = 'For Sale'
      data.auction = true
      break
    case 'Bank Repossessed':
      data.listing_type = 'For Sale'
      data.bank_repossessed = true
      break
    case 'Bank Assisted':
      data.listing_type = 'For Sale'
      data.distressed = true
      break
    case 'Student Accommodation':
      data.listing_type = 'To Let'
      data.model__in = [ 'residential' ]
      data.student_accommodation = true
      break
    default:
      break
  }

  Object.keys(other).forEach(k => {
    if (other[k] === '') {
      delete other[k]
    }
  })
  return includeAdvanced ? { ...data, ...other } : data
}

export const LightSearch = (): React.ReactNode => {
  const [ initialized, setInitialized ] = useState(false)
  const [ initvals, setInitvals ] = useState(initialForm)
  useEffect(() => {
    initializeSearch().then(async () => {
      setInitvals(await prefillInitialForm(initialForm))
      setInitialized(true)
    })
  }, [ initialized ])
  return (
    <>
      <svg aria-hidden={true} style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }} viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="icon16-Check-Small" viewBox="0 0 17 13">
            <path d="M6.64085 7.69738C6.58412 7.75378 6.49177 7.75378 6.43504 7.69738L2.61285 3.89996L0 6.49852L3.82144 10.2987L6.53836 13L17 2.59708L14.3849 0L6.64085 7.69738Z" />
          </symbol>
        </defs>
      </svg>
      <Formik
        initialValues={initvals}
        enableReinitialize
        onSubmit={(values: SearchForm) => {
          const values_string = JSON.stringify(values)
          const encodedString = Buffer.from(values_string).toString('base64')
          const form = document.createElement('form')
          if (!window.request.search_defaults.branch__in) {
            window.localStorage.setItem('pd_search', values_string)
          }
          form.setAttribute('method', 'POST')
          form.setAttribute('action', '/results/setup-search-results/')
          const input = document.createElement('input')
          const valuesInput = document.createElement('input')
          const token = document.createElement('input')
          valuesInput.setAttribute('name', 'search_values')
          valuesInput.setAttribute('value', encodedString)
          input.setAttribute('name', 'search_params')
          input.setAttribute('value', encodedString)
          token.setAttribute('name', 'csrfmiddlewaretoken')
          token.setAttribute('value', csrftoken || '')
          form.appendChild(input)
          form.appendChild(valuesInput)
          form.appendChild(token)
          document.body.appendChild(form)
          form.submit()
        }}
      >{props => {
          const { values } = props

          const [ propertyCount, setPropertyCount ] = useState<number>(0)

          const {
            buy_rent,
            category,
            locations,
            min_price,
            max_price,
            size_from,
            size_to,
            min_beds,
            on_show,
            has_flat_or_accomm,
            pets_allowed,
            furnished
          } = values

          const fetchLocations: loadOptions = async (inputValue): Promise<Options<OptionType<string>>> => {
            if (!inputValue) {
              return []
            }
            try {
              const results = await axios.post(api('/location-search/'), { ...values, term: inputValue }, { headers: { 'X-CSRFToken': csrftoken } })
              return results.data.map(result => ({
                value: `{"id": ${result.id}, "type": "${result.type}"}`,
                id: result.id,
                name: result.label,
                label: result.name,
                type: result.type
              })) as unknown as Options<OptionType<string>>
            } catch (e) {
              console.error(e)
            }
            return []
          }

          const categoriesOptions: OptionType<string>[] = window.searchData && buy_rent ? (
            uniqueArray(getIn(window.searchData, buy_rent, []) || []).map(listing_type => ({
              value: listing_type, label: listing_type
            })).sort((a, b) => CATEGORIES.indexOf(a.value) - CATEGORIES.indexOf(b.value))
          ) : []

          const resetAdvanced = (additionalProps: SearchForm) => {
            setTimeout(() => {
              props.setValues(prevState => (
                {
                  ...prevState,
                  ...additionalProps,
                  has_flat_or_accomm: undefined,
                  pets_allowed: undefined,
                  on_show: undefined,
                  furnished: undefined,
                  min_price: '',
                  max_price: '',
                  web_ref_search: '',
                  additional_locations: [],
                  min_beds: undefined,
                  size_from: '',
                  size_to: ''
                }
              ))
            })
          }

          useEffect(() => {
            let cat = categoriesOptions.length ? categoriesOptions[0].value : 'Residential'
            if (category && categoriesOptions.find(c => c.value === category)) {
              cat = category
            }
            if (props.touched.buy_rent) {
              resetAdvanced({ category: cat as string })
            }
          }, [ buy_rent, category, initialized ])


          useEffect(() => {
            if (props.touched.buy_rent || props.touched.category) {
              resetAdvanced({ locations })
            }
          // @ts-ignore
          }, [ buy_rent, category, initialized ])

          useEffect(() => {
            if (!category || !buy_rent) { return }
            clearTimeout(countTimer)
            countTimer = setTimeout(() => {
              axios.post(api('/property-count/'), getPostData(props.values, {}), { headers: { 'X-CSRFToken': csrftoken } }).then(resp => setPropertyCount(resp.data.count)).catch(e => console.error(e))
            }, 300)
          }, [
            buy_rent,
            category,
            locations,
            min_price,
            max_price,
            size_from,
            size_to,
            min_beds,
            on_show,
            has_flat_or_accomm,
            pets_allowed,
            furnished,
            initialized
          ])
          return (
            <Form>
              <div className="search-light">
                <Field
                  component={SearchFormSelect}
                  name='buy_rent'
                  hideSelectedOptions={buySelectOptions?.length !== 1}
                  options={buySelectOptions}
                />
                <div className="spacer" />
                <Field
                  component={SearchFormSelect}
                  name='category'
                  hideSelectedOptions={categoriesOptions?.length !== 1}
                  options={categoriesOptions}
                />
                <div className="spacer" />
                <Field
                  component={LocationSelect}
                  name='locations'
                  truncate={2}
                  icon={<PinIcon />}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  placeholder="Search by Area, Suburb or Listing ID"
                  loadOptions={fetchLocations}
                  isClearable={false}
                  isMulti
                />
                <Button
                  type="button"
                  onClick={() => {
                    if (propertyCount) {
                      props.handleSubmit()
                    }
                  }}
                  variant={propertyCount ? 'primary' : 'disabled'}
                >
                  <SearchIcon />
                SEARCH
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
